import styles from "../assets/css/Footer.module.css";

export const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <p>Alley Cat Development</p>
      <p>Austin, Texas</p>
      <a href="mailto:hi@alleycat.dev">hi@alleycat.dev</a>
    </div>
  );
};
