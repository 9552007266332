import { Link, useMatch } from "react-router-dom";
import styles from "../assets/css/Header.module.css";

export const Header = () => {
  const match = useMatch("/");
  const startClassnames = [styles.section].join(" ");
  const middleClassnames = [styles.section, styles.middle].join(" ");
  const endClassnames = [styles.section, styles.end].join(" ");
  return (
    <div className={styles.wrapper}>
      <div className={startClassnames}>
        {!match ? <Link to="/">home</Link> : <div></div>}
      </div>
      <div className={middleClassnames}>
        <h1 className={styles.title}>Alley Cat Development</h1>
      </div>
      <div className={endClassnames}>
        <Link to="/about">about</Link>
      </div>
    </div>
  );
};
