import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

const Main = lazy(async () => await import("./pages/Main"));
const Piece = lazy(async () => await import("./pages/Piece"));
const About = lazy(async () => await import("./pages/About"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<p>loading...</p>}>
        <div className="app-wrapper">
          <Header />
          <div className="wrapper">
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/piece/:id" element={<Piece />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
